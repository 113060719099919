import * as styles from './Controls.module.scss'

import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useRecoilState } from 'recoil'

import * as controls from 'components/controls'

import { ScratchpadState } from './data/state'
import {
  showHelpState,
  showPasteInputState,
  viewModeState,
  ViewMode,
} from './data/recoilState'

import AddCards from './AddCards'

import Check from './icons/Check'

interface Props {
  state: ScratchpadState
}

const Controls: React.FC<Props> = (props) => {
  const { state } = props

  const [viewMode, setViewMode] = useRecoilState(viewModeState)
  const [, setShowHelp] = useRecoilState(showHelpState)
  const [, showPasteInput] = useRecoilState(showPasteInputState)

  const totalCards = useMemo(() => {
    return state.data.groups.reduce(
      (result, group) =>
        result + group.items.filter((item) => item.card).length,
      0,
    )
  }, [state.data.groups])

  const [copied, setCopied] = useState(false)

  const copyToClipboard = useCallback(() => {
    state.copy()
    setCopied(true)

    const timer = setTimeout(() => {
      setCopied(false)
    }, 1500)

    return () => {
      clearInterval(timer)
    }
  }, [state])

  return (
    <div className={styles.container}>
      <AddCards state={state} />

      <div className={styles.right}>
        {totalCards > 0 && (
          <div className={styles.summary}>{totalCards} cards</div>
        )}

        <controls.SmallButton
          onClick={() => showPasteInput(true)}
          title="Paste Card List"
          secondary
        >
          Paste
        </controls.SmallButton>

        <controls.SmallButton
          onClick={() => state.reset()}
          title="Clear all content"
          secondary
        >
          Reset
        </controls.SmallButton>

        <controls.SmallButton
          onClick={() => setShowHelp(true)}
          title="About this tool"
          secondary
        >
          ?
        </controls.SmallButton>

        <controls.SegmentedControl
          segments={{
            images: 'Images',
            text: 'Text',
            details: 'Details',
          }}
          value={viewMode}
          onChange={(event) =>
            setViewMode(event.currentTarget.value as ViewMode)
          }
        />

        <div className={styles.copy} title="Copy to clipboard as Markdown">
          <controls.SmallButton onClick={copyToClipboard} secondary>
            Copy
          </controls.SmallButton>

          <div
            className={classNames(styles.copiedNotification, {
              [styles.visible]: copied,
            })}
          >
            <div>Copied to Clipboard</div>
            <div>
              <Check color="#ffffff" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Controls
