import * as styles from './Cards.module.scss'

import React from 'react'

import { ScratchpadState } from '../data/state'
import { Item } from '../data/types'

import Card from './Card'

interface Props {
  state: ScratchpadState
  groupIndex: number
  items: Item[]
}

const Cards: React.FC<Props> = (props) => {
  const { state, groupIndex, items } = props

  if (items.length === 0) {
    return <div className={styles.empty}>No Cards</div>
  }

  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <Card
          key={item.id}
          state={state}
          item={item}
          index={[groupIndex, index]}
        />
      ))}
    </div>
  )
}

export default Cards
