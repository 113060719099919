import React, { useMemo } from 'react'
import CardSymbol, { CardSymbolType } from 'components/CardSymbol'

interface Props {
  children: string
}

const symbolPattern = /\{(.+?)\}/g

type TextElement =
  | {
      type: 'text'
      value: string
    }
  | { type: 'symbol'; value: CardSymbolType }

const parseSymbols = (value: string): TextElement[] => {
  let match: RegExpExecArray | null
  const result: TextElement[] = []
  let index = 0

  while ((match = symbolPattern.exec(value))) {
    if (match.index > index) {
      const substring = value.substring(index, match.index)
      result.push({
        type: 'text',
        value: substring,
      })
    }

    index = match.index + match[0].length

    result.push({
      type: 'symbol',
      value: match[1].toLowerCase() as CardSymbolType,
    })
  }
  if (index < value.length) {
    result.push({
      type: 'text',
      value: value.substring(index, value.length),
    })
  }

  return result
}

const parseCardText = (value: string): TextElement[][] => {
  return value.split('\n').map((paragraph) => parseSymbols(paragraph))
}

/**
 * Renders Magic rules text replacing bracketed symbols with symbol components
 * and splitting line breaks into paragraphs.
 *
 * Does not currently italicize reminder text, but should.
 */
const RulesText: React.FC<Props> = (props) => {
  const { children } = props

  const parsedText = useMemo(() => parseCardText(children), [children])

  return (
    <div>
      {parsedText.map((paragraph, index) => (
        <p key={index}>
          {paragraph.map((element, index) =>
            element.type === 'symbol' ? (
              <CardSymbol key={index}>{element.value}</CardSymbol>
            ) : (
              <React.Fragment key={index}>{element.value}</React.Fragment>
            ),
          )}
        </p>
      ))}
    </div>
  )
}

export default RulesText
