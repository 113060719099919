import { atom } from 'recoil'

import { RecoilState, AtomOptions, AtomEffect } from 'recoil'

const persistAtom =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    if (typeof window === 'undefined') {
      return
    }

    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setTimeout(() => {
        setSelf(JSON.parse(savedValue))
      }, 0)
    }

    try {
      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue))
      })
    } catch {
      // no-op
    }
  }

const persistentAtom = <T>(options: AtomOptions<T>): RecoilState<T> => {
  return atom({
    ...options,
    effects_UNSTABLE: [
      ...(options.effects_UNSTABLE || []),
      persistAtom(options.key),
    ],
  })
}

export { persistentAtom }
