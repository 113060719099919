import * as styles from './Card.module.scss'

import React, { useCallback, useState, useRef } from 'react'
import classNames from 'classnames'

import { TextButton } from 'components/controls'
import { UnstyledCardHover as CardHover } from 'components/cards/CardHover'

import { Item } from '../data/types'
import { ScratchpadState } from '../data/state'

interface Props {
  state: ScratchpadState
  item: Item
  index: [number, number]
}

const Card: React.FC<Props> = (props) => {
  const { state, item, index } = props

  const { moveCard, removeCard } = state

  const element = useRef<HTMLDivElement | null>(null)

  const [dragging, setDragging] = useState(false)

  // Use a count rather than bool since order isn't guaranteed
  const [dragCount, setDragCount] = useState(0)

  const onClick = useCallback(() => {
    element.current?.focus()
  }, [])

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Backspace') {
        removeCard(index)
      } else if (event.key === 'Escape') {
        element.current?.blur()
      }
    },
    [index, removeCard],
  )

  const onDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.dataTransfer.setData('application/item', item.id)
      event.dataTransfer.dropEffect = 'move'

      setDragging(true)
    },
    [item.id],
  )

  const onDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(false)
  }, [])

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (event.dataTransfer.types.includes('application/item')) {
      event.stopPropagation()
      setDragCount((value) => value + 1)
    }
  }, [])

  const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (event.dataTransfer.types.includes('application/item')) {
      event.stopPropagation()
      setDragCount((value) => value - 1)
    }
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      setDragCount(0)

      const itemID = event.dataTransfer.getData('application/item')
      if (itemID) {
        event.stopPropagation()
        moveCard(itemID, index)
      }
    },
    [index, moveCard],
  )

  return (
    <div
      className={classNames(styles.container, {
        [styles.dragging]: dragging,
        [styles.dragOver]: dragCount,
      })}
      tabIndex={0}
      ref={element}
      onClick={onClick}
      onKeyDown={onKeyDown}
      draggable
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {item.card && (
        <>
          <div className={styles.cardName}>
            <CardHover card={item.card.name} disabled={dragging}>
              {item.card.name}
            </CardHover>
          </div>

          {item.note && <div className={styles.note}>{item.note}</div>}
        </>
      )}

      {item.error && (
        <div className={styles.error}>
          <div>
            <span className={styles.errorTitle}>Error:</span> {item.note}{' '}
            <TextButton onClick={() => removeCard(index)}>Remove</TextButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default Card
