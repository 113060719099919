import * as styles from './Group.module.scss'

import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import { useRecoilState, useRecoilValue } from 'recoil'

import { viewModeState, selectedGroupState } from '../data/recoilState'
import { ScratchpadState } from '../data/state'
import { Group } from '../data/types'

import CardsImages from '../Images/Cards'
import CardsText from '../Text/Cards'
import CardsDetails from '../Details/Cards'

import Heading from './Heading'

interface Props {
  group: Group
  groupIndex: number
  state: ScratchpadState
}

const GroupComponent: React.FC<Props> = (props) => {
  const { group, state, groupIndex } = props

  const viewMode = useRecoilValue(viewModeState)
  const [selectedGroup, setSelectedGroup] = useRecoilState(selectedGroupState)

  const [draggable, setDraggable] = useState(false)
  const [dragging, setDragging] = useState(false)

  // Use a count rather than bool since order isn't guaranteed
  const [dragCount, setDragCount] = useState(0)

  const { moveCard, moveGroup } = state

  const onMouseDown = useCallback(() => {
    setSelectedGroup(groupIndex)
  }, [groupIndex, setSelectedGroup])

  const onDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.dataTransfer.setData('application/group', group.id)
      event.dataTransfer.dropEffect = 'move'

      setDragging(true)
    },
    [group.id],
  )

  const onDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDraggable(false)
    setDragging(false)
  }, [])

  const onDragEnter = useCallback(() => {
    setDragCount((value) => value + 1)
  }, [])

  const onDragLeave = useCallback(() => {
    setDragCount((value) => value - 1)
  }, [])

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      setDragCount(0)

      const itemID = event.dataTransfer.getData('application/item')
      const groupID = event.dataTransfer.getData('application/group')

      if (itemID) {
        moveCard(itemID, [groupIndex, group.items.length])
      } else if (groupID) {
        moveGroup(groupID, groupIndex)
      }
    },
    [group.items.length, groupIndex, moveCard, moveGroup],
  )

  const showHeading = state.data.groups.length > 1 || group.name

  return (
    <div
      className={classNames(styles.container, {
        [styles.dragging]: dragging,
        [styles.dragOver]: dragCount,
      })}
      onMouseDown={onMouseDown}
      draggable={draggable}
      onDragStart={draggable ? onDragStart : undefined}
      onDragEnd={draggable ? onDragEnd : undefined}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {showHeading && (
        <Heading
          group={group}
          groupIndex={groupIndex}
          selected={groupIndex === selectedGroup}
          state={state}
          setDraggable={setDraggable}
        />
      )}

      <div className={styles.cards}>
        {viewMode === 'images' ? (
          <CardsImages
            state={state}
            groupIndex={groupIndex}
            items={group.items}
          />
        ) : viewMode === 'text' ? (
          <CardsText
            state={state}
            groupIndex={groupIndex}
            items={group.items}
          />
        ) : viewMode === 'details' ? (
          <CardsDetails
            state={state}
            groupIndex={groupIndex}
            items={group.items}
          />
        ) : null}
      </div>
    </div>
  )
}

export default GroupComponent
