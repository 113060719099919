import * as styles from './Actions.module.scss'

import React from 'react'

import { Item, SimplifiedCard } from '../data/types'

interface Props {
  item: Item & { card: SimplifiedCard }
}

const Actions: React.FC<Props> = (props) => {
  const { item } = props
  const { card } = item

  return (
    <div className={styles.container}>
      <div className={styles.links}>
        <a
          href={`https://scryfall.com/search?q=%21%22${card.name}%22`}
          target="_blank"
          rel="noreferrer"
        >
          View on Scryfall
        </a>

        <a
          href={`https://cubecobra.com/tool/card/${
            card.cardFaces ? card.cardFaces[0].name : card.name
          }`}
          target="_blank"
          rel="noreferrer"
        >
          View on Cube Cobra
        </a>
      </div>
    </div>
  )
}

export default Actions
