import * as styles from './FaceAttributes.module.scss'

import React from 'react'

import { CardFace } from 'utils/mtg/simplifyCards'

import ManaCost from 'components/ManaCost'

import RulesText from './RulesText'

interface Props {
  face: CardFace
}

const FaceAttributes: React.FC<Props> = (props) => {
  const { face } = props

  return (
    <div>
      <div className={styles.cardHeading}>
        <div className={styles.name}>{face.name}</div>

        {face.manaCost && (
          <div>
            <ManaCost>{face.manaCost}</ManaCost>
          </div>
        )}
      </div>

      {face.typeLine && <div className={styles.type}>{face.typeLine}</div>}

      {face.oracleText && (
        <div className={styles.cardText}>
          <RulesText>{face.oracleText}</RulesText>
        </div>
      )}

      {face.flavorText && (
        <p className={styles.flavorText}>{face.flavorText}</p>
      )}

      {face.power && face.toughness && (
        <div className={styles.pt}>
          {face.power}/{face.toughness}
        </div>
      )}

      {face.loyalty && (
        <div className={styles.loyalty}>Loyalty: {face.loyalty}</div>
      )}
    </div>
  )
}

export default FaceAttributes
