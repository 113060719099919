import * as scryfall from 'utils/scryfall'
import * as mtg from 'utils/mtg'

import { Data } from './types'
import { uniqueID } from './uniqueID'

const headingPattern = /^#+ *(?<heading>.+)/
const quotePattern = /^ *> *(?<quote>.*)/
const cardPattern = /^ *\d*x? *(?<card>.+?)( ?\/\/|$)/

export const importPlainText = async (value: string): Promise<Data | null> => {
  let lines = value
    .split('\n')
    .map((value) => value.trim())
    .filter((value) => value.length > 0)

  if (lines.length > 300) {
    // eslint-disable-next-line no-console
    console.log(
      'Warning: Long import, only the first 300 non-blank lines will be imported',
    )
    lines = lines.slice(0, 300)
  }

  const groups = lines.reduce((result, value) => {
    const heading = value.match(headingPattern)?.groups?.heading
    const quote = value.match(quotePattern)?.groups?.quote
    const card = value.match(cardPattern)?.groups?.card

    if (heading) {
      result.push({ name: heading, items: [] })
    } else if (quote! + null) {
      const group = result[result.length - 1]
      const item = group?.items[group.items.length - 1]
      if (item) {
        if (!item.note) {
          item.note = quote
        } else {
          item.note = `${item.note}\n${quote}`
        }
      }
    } else if (card) {
      if (result.length === 0) {
        result.push({ items: [] })
      }

      result[result.length - 1].items.push({ name: card })
    }

    return result
  }, [] as { name?: string; items: { name: string; note?: string }[] }[])

  const cardNames = groups.flatMap((group) =>
    group.items.map((item) => item.name),
  )

  return scryfall.fetchCollection(cardNames).then((result) => {
    const cards = mtg.simplifyCards(result.cards)

    const notFound = result.notFound?.map((id) => (id as any)?.name)

    let index = 0

    const resultGroups = groups.map((group) => {
      return {
        id: uniqueID(),
        name: group.name,
        items: group.items.map((item) => {
          if (notFound?.includes(item.name)) {
            return {
              id: uniqueID(),
              error: true,
              note: `Card named "${item.name}" not found`,
            }
          } else {
            const card = cards[index++]
            return {
              id: uniqueID(),
              cardScryfallID: card.scryfallID,
              card,
              note: item.note,
            }
          }
        }),
      }
    })

    return {
      groups: resultGroups,
    }
  })
}

export const exportPlainText = (data: Data): string => {
  return data.groups
    .map((group) => {
      const result = group.items.map((item) => {
        if (item.error || item.card == null) {
          return null
        }
        if (item.note != null && item.note.length > 0) {
          const formattedNote = item.note
            .split('\n')
            .map((line) => `> ${line}`)
            .join('\n')
          return `${item.card.name}\n\n${formattedNote}\n`
        }

        return item.card.name
      })
      if (group.name) {
        result.unshift(`# ${group.name}\n`)
      }

      return result.join('\n')
    })
    .join('\n\n')
}
