import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import update from 'immutability-helper'

import * as scryfall from 'utils/scryfall'
import * as mtg from 'utils/mtg'
import { copyToClipboard } from 'utils/copyToClipboard'
import { usePersistentState } from 'utils/usePersistentState'

import { uniqueID, resetID } from './uniqueID'
import { importPlainText, exportPlainText } from './serialization'

import { Data, Group } from './types'

const defaultData: Data = {
  groups: [{ id: 'default', items: [] }],
}

const newLineRegex = /^\n$|^\r$|^\s{1,}$|^$/

const fetchCards = async (text: string) => {
  const cardNames = text.split('\n').filter((name) => !name.match(newLineRegex))

  const result = await scryfall.fetchCollection(cardNames)

  return mtg.simplifyCards(result.cards)
}

const findIndex = (data: Data, id: string): [number, number] => {
  for (let groupIndex = 0; groupIndex < data.groups.length; groupIndex++) {
    const group = data.groups[groupIndex]
    const index = group.items.findIndex((item) => item.id === id)
    if (index !== -1) {
      return [groupIndex, index]
    }
  }

  throw 'Item with id not found'
}

const groupEmpty = (group: Group) => {
  return isEmpty(group.name) && isEmpty(group.items)
}

export const useScratchpadState = () => {
  const [data, setData] = usePersistentState<Data>(
    'scratchpad-data',
    defaultData,
    (value) => {
      if (!value.groups?.length) {
        return defaultData
      }

      const maxID = value.groups.reduce(
        (result, group) =>
          Math.max(
            result,
            parseInt(group.id),
            group.items.reduce(
              (itemResult, item) => Math.max(itemResult, parseInt(item.id)),
              0,
            ),
          ),
        0,
      )

      resetID(maxID)

      return value
    },
  )

  const reset = useCallback(() => {
    resetID(0)
    setData(defaultData)
  }, [setData])

  const importCards = useCallback(
    (text: string, replace = true) => {
      importPlainText(text).then((newData) => {
        if (replace) {
          setData(newData || defaultData)
        } else if (newData != null) {
          setData(
            update(data, {
              groups: { $push: newData.groups },
            }),
          )
        }
      })
    },
    [data, setData],
  )

  const addCard = useCallback(
    (cardName: string, group: number) => {
      fetchCards(cardName).then((cards) => {
        const newItems = cards.map((card) => ({
          id: uniqueID(),
          cardScryfallID: card.scryfallID,
          card,
        }))

        const validGroup =
          typeof group === 'number' && group < data.groups.length && group >= 0
            ? group
            : 0

        setData(
          update(data, {
            groups: { [validGroup]: { items: { $push: newItems } } },
          }),
        )
      })
    },
    [data, setData],
  )

  const removeCard = useCallback(
    (index: [number, number]) => {
      let result = update(data, {
        groups: { [index[0]]: { items: { $splice: [[index[1], 1]] } } },
      })

      if (groupEmpty(result.groups[index[0]])) {
        result = update(result, { groups: { $splice: [[index[0], 1]] } })
      }

      setData(result)
    },
    [data, setData],
  )

  const moveCard = useCallback(
    (itemID: string, index: [number, number]) => {
      const source = findIndex(data, itemID)

      const item = data.groups[source[0]].items[source[1]]

      let result = update(data, {
        groups: {
          [source[0]]: {
            items: {
              $splice: [[source[1], 1]],
            },
          },
        },
      })

      result = update(result, {
        groups: {
          [index[0]]: {
            items: {
              $splice: [[index[1], 0, item]],
            },
          },
        },
      })

      if (groupEmpty(result.groups[source[0]])) {
        result = update(result, { groups: { $splice: [[source[0], 1]] } })
      }

      setData(result)
    },
    [data, setData],
  )

  const moveCardToNewGroup = useCallback(
    (itemID: string) => {
      const source = findIndex(data, itemID)

      const item = data.groups[source[0]].items[source[1]]

      let result = update(data, {
        groups: {
          [source[0]]: {
            items: {
              $splice: [[source[1], 1]],
            },
          },
        },
      })

      result.groups.push({
        id: uniqueID(),
        items: [item],
      })

      if (groupEmpty(result.groups[source[0]])) {
        result = update(result, { groups: { $splice: [[source[0], 1]] } })
      }

      setData(result)
    },
    [data, setData],
  )

  const updateItem = useCallback(
    (index: [number, number], attributes: { note?: string }) => {
      const result = update(data, {
        groups: {
          [index[0]]: {
            items: {
              [index[1]]: {
                $set: {
                  ...data.groups[index[0]].items[index[1]],
                  ...attributes,
                },
              },
            },
          },
        },
      })

      setData(result)
    },
    [data, setData],
  )

  const moveGroup = useCallback(
    (groupID: string, index: number) => {
      const source = data.groups.findIndex((group) => group.id === groupID)

      const group = data.groups[source]

      const result = update(data, {
        groups: {
          $splice: [
            [source, 1],
            [index, 0, group],
          ],
        },
      })

      setData(result)
    },
    [data, setData],
  )

  const removeGroup = useCallback(
    (index: number) => {
      const result = update(data, { groups: { $splice: [[index, 1]] } })
      setData(result)
    },
    [data, setData],
  )

  const updateGroup = useCallback(
    (index: number, attributes: { name?: string }) => {
      const result = update(data, {
        groups: {
          [index]: { $set: { ...data.groups[index], ...attributes } },
        },
      })

      setData(result)
    },
    [data, setData],
  )

  const copy = useCallback(() => {
    copyToClipboard(exportPlainText(data))
  }, [data])

  return {
    data,
    reset,
    importCards,
    addCard,
    removeCard,
    moveCard,
    moveCardToNewGroup,
    updateItem,
    moveGroup,
    removeGroup,
    updateGroup,
    copy,
  }
}

export type ScratchpadState = ReturnType<typeof useScratchpadState>
