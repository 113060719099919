// extracted by mini-css-extract-plugin
export var container = "Card-module--container--25ab7";
export var controls = "Card-module--controls--a0db4";
export var dragHandle = "Card-module--dragHandle--ef32f";
export var dragOver = "Card-module--dragOver--2bd92";
export var dragging = "Card-module--dragging--4184b";
export var error = "Card-module--error--6828e";
export var errorCard = "Card-module--errorCard--43d3d";
export var errorCardRatio = "Card-module--errorCardRatio--084e6";
export var errorDetails = "Card-module--errorDetails--d2503";
export var errorTitle = "Card-module--errorTitle--e22ce";
export var image = "Card-module--image--80103";
export var remove = "Card-module--remove--3abc2";