import * as styles from './NewGroup.module.scss'

import React, { useState, useCallback } from 'react'
import classNames from 'classnames'

import { ScratchpadState } from './data/state'

interface Props {
  state: ScratchpadState
}

const NewGroup: React.FC<Props> = (props) => {
  const { state } = props

  const [dragCount, setDragCount] = useState(0)

  const onDragEnter = useCallback(() => {
    setDragCount((value) => value + 1)
  }, [])

  const onDragLeave = useCallback(() => {
    setDragCount((value) => value - 1)
  }, [])

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      setDragCount(0)

      const itemID = event.dataTransfer.getData('application/item')
      if (itemID) {
        state.moveCardToNewGroup(itemID)
      }
    },
    [state],
  )

  return (
    <div
      className={classNames(styles.container, {
        [styles.dragOver]: dragCount,
      })}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      Create New Group
    </div>
  )
}

export default NewGroup
