import * as styles from './Heading.module.scss'

import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { ScratchpadState } from '../data/state'
import { Group } from '../data/types'

interface Props {
  group: Group
  groupIndex: number
  selected: boolean
  state: ScratchpadState
  setDraggable(value: boolean): void
}

const Heading: React.FC<Props> = (props) => {
  const { group, groupIndex, selected, state, setDraggable } = props

  const cardCount = group.items.filter((item) => item.card).length

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [editing, setEditing] = useState(false)

  useLayoutEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus()
    }
  })

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      inputRef.current?.blur()
    }
  }, [])

  return (
    <div
      className={classNames(styles.container, {
        [styles.editing]: editing,
      })}
    >
      {editing ? (
        <input
          ref={inputRef}
          type="text"
          className={styles.input}
          value={group.name || ''}
          onChange={(event) => {
            state.updateGroup(groupIndex, {
              name: event.currentTarget.value,
            })
          }}
          onBlur={() => setEditing(false)}
          onKeyDown={onKeyDown}
        />
      ) : (
        <div className={styles.name} onClick={() => setEditing(true)}>
          {group.name ? (
            <span>{group.name}</span>
          ) : (
            <span className={styles.namePlaceholder}>Unnamed Group</span>
          )}{' '}
          <span>
            {' '}
            - {cardCount} {cardCount === 1 ? 'Card' : 'Cards'}
          </span>
          {selected && <span className={styles.selected}>&bull;</span>}
        </div>
      )}

      <div className={styles.controls}>
        <div
          className={styles.dragHandle}
          onMouseDown={() => setDraggable(true)}
          onMouseUp={() => setDraggable(false)}
          title="Drag to reorder group"
        >
          ⣿⣿
        </div>

        <button
          className={styles.remove}
          onClick={() => state.removeGroup(groupIndex)}
          title={group.name ? `Remove group ${group.name}` : 'Remove group'}
        >
          ×
        </button>
      </div>
    </div>
  )
}

export default Heading
