import * as styles from './Modal.module.scss'

import React, { useEffect } from 'react'

interface Props {
  visible: boolean
  onDismiss(): void
  children: React.ReactNode
}

const Modal: React.FC<Props> = (props) => {
  const { visible, onDismiss, children } = props

  useEffect(() => {
    if (visible) {
      const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onDismiss()
        }
      }
      document.addEventListener('keydown', onKeyDown)

      return () => {
        document.removeEventListener('keydown', onKeyDown)
      }
    }
  }, [onDismiss, visible])

  if (!visible) {
    return null
  }

  return (
    <>
      <div className={styles.overlay} onClick={onDismiss} />

      <div className={styles.container}>
        <div className={styles.box} role="dialog">
          <button className={styles.close} onClick={onDismiss}>
            ×
          </button>

          <div>{children}</div>
        </div>
      </div>
    </>
  )
}

export default Modal
