import * as styles from './Notes.module.scss'

import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'

import * as controls from 'components/controls'

import { ScratchpadState } from '../data/state'
import { Item } from '../data/types'

interface Props {
  state: ScratchpadState
  item: Item
  index: [number, number]
}

const Notes: React.FC<Props> = (props) => {
  const { state, item, index } = props

  const { updateItem } = state

  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const [editing, setEditing] = useState(false)

  useLayoutEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus()
    }
  })

  const updateNote = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      updateItem(index, { note: event.currentTarget.value })
    },
    [index, updateItem],
  )

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || (event.key === 'Enter' && event.metaKey)) {
      inputRef.current?.blur()
    }
  }, [])

  if (editing) {
    return (
      <div className={styles.note}>
        <textarea
          className={styles.noteInput}
          ref={inputRef}
          value={item.note || ''}
          onChange={updateNote}
          onBlur={() => setEditing(false)}
          onKeyDown={onKeyDown}
        />
      </div>
    )
  }

  if (!isEmpty(item.note)) {
    return (
      <div
        className={styles.note}
        onClick={(event) => {
          setEditing(true)
          event.stopPropagation()
        }}
      >
        <div className={styles.noteContent} title="Edit note">
          {item.note}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.addNote}>
      <controls.SmallButton
        onClick={() => {
          setEditing(true)
        }}
        secondary
      >
        Add Note
      </controls.SmallButton>
    </div>
  )
}

export default Notes
