import { atom } from 'recoil'
import { persistentAtom } from 'utils/persistentAtom'

export type ViewMode = 'images' | 'text' | 'details'

export const viewModeState = persistentAtom<ViewMode>({
  key: 'scratchpad-view-mode',
  default: 'images',
})

export const showHelpState = atom({
  key: 'scratchpad-show-help',
  default: false,
})

export const showPasteInputState = atom({
  key: 'scratchpad-show-paste-input',
  default: false,
})

export const selectedGroupState = atom<number | null>({
  key: 'scratchpad-selected-group',
  default: null,
})
