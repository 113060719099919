// title: Magic: the Gathering Scratchpad

import React from 'react'

import { AccentColor } from 'utils/accent-color/AccentColorContext'

import { BaseLayout } from 'src/components/layout/Base'
import PageMetaData from 'components/layout/PageMetaData'

import Scratchpad from 'components/scratchpad/Scratchpad'

interface Props {
  pageContext: {
    url: string
  }
}

const ScratchpadPage: React.FC<Props> = () => {
  return (
    <BaseLayout>
      <AccentColor color="#039885">
        <Scratchpad />
      </AccentColor>
    </BaseLayout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Magic: the Gathering Scratchpad — Lucky Paper',
        description:
          'MTG Scratchpad a tool for making quick Magic: the Gathering card lists.',
        ogType: 'website',
      }}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: 'Magic: the Gathering Scratchpad — Lucky Paper',
        url: props.pageContext.url,
      }}
    />
  )
}

export default ScratchpadPage
