import * as styles from './PasteInput.module.scss'

import React, { useCallback, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

import * as controls from 'components/controls'

import { showPasteInputState } from './data/recoilState'

import { ScratchpadState } from './data/state'

import Modal from './Modal'

interface Props {
  state: ScratchpadState
}

const PasteInput: React.FC<Props> = (props) => {
  const { state } = props

  const [showPasteInput, setShowPasteInputState] =
    useRecoilState(showPasteInputState)

  const [value, setValue] = useState('')

  const scratchpadIsEmpty = useMemo(() => {
    return (
      state.data.groups.length === 0 ||
      (state.data.groups.length === 1 &&
        state.data.groups[0].items.length === 0)
    )
  }, [state.data.groups])

  const submit = useCallback(
    (replace: boolean) => {
      if (value.length > 0) {
        state.importCards(value, replace)
        setValue('')
        setShowPasteInputState(false)
      }
    },
    [setShowPasteInputState, state, value],
  )

  return (
    <Modal
      visible={showPasteInput}
      onDismiss={() => setShowPasteInputState(false)}
    >
      <div className={styles.container}>
        <controls.Label title="Paste Card List">
          <controls.TextArea
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
            rows={20}
            placeholder="Lightning Bolt&#10;Rampant Growth"
          />
        </controls.Label>

        <div className={styles.controls}>
          <controls.ControlGroup>
            <controls.SmallButton
              secondary
              disabled={scratchpadIsEmpty}
              onClick={() => {
                submit(true)
              }}
            >
              Replace Cards
            </controls.SmallButton>
            <controls.SmallButton
              onClick={() => {
                submit(false)
              }}
            >
              Add Cards
            </controls.SmallButton>
          </controls.ControlGroup>
        </div>
      </div>
    </Modal>
  )
}

export default PasteInput
