import * as styles from './Header.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import { ScratchpadState } from './data/state'

import Controls from './Controls'

interface Props {
  state: ScratchpadState
}

const Header: React.FC<Props> = (props) => {
  const { state } = props

  return (
    <>
      <h1 className={styles.heading}>
        MTG Scratchpad by <Link to="/">Lucky Paper</Link>
      </h1>

      <div className={styles.controls}>
        <Controls state={state} />
      </div>
    </>
  )
}

export default Header
