import * as styles from './Footer.module.scss'

import React from 'react'

const Footer: React.FC = () => {
  return (
    <div className={styles.container}>
      <div>
        Card content and images copyright Wizards of the Coast, LLC. Data and
        card images provided by{' '}
        <a href="http://scryfall.com" target="_blank" rel="noreferrer">
          Scryfall
        </a>
        .
      </div>
    </div>
  )
}

export default Footer
