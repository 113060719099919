import * as styles from './Attributes.module.scss'

import React from 'react'

import { SimplifiedCard } from '../data/types'

import FaceAttributes from './FaceAttributes'

interface Props {
  card: SimplifiedCard
}

const Attributes: React.FC<Props> = (props) => {
  const { card } = props

  return (
    <div className={styles.container}>
      {card.cardFaces ? (
        <>
          {card.cardFaces.map((face, index) => (
            <div key={index} className={styles.face}>
              <FaceAttributes face={face} />
            </div>
          ))}
        </>
      ) : (
        <div className={styles.face}>
          <FaceAttributes face={card} />
        </div>
      )}

      <div className={styles.illustrator}>
        Illustrated by{' '}
        <a
          href={`https://scryfall.com/search?q=a%3A“${card.artist}”&unique=art`}
          target="_blank"
          rel="noreferrer"
        >
          {card.artist}
        </a>
      </div>
    </div>
  )
}

export default Attributes
