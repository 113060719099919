import * as styles from './ImportOptions.module.scss'

import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { isEmpty } from 'lodash'

import * as controls from 'components/controls'

import { ScratchpadState } from './data/state'

import Modal from './Modal'

interface Props {
  state: ScratchpadState
  pendingImport: string | null
  setPendingImport: React.Dispatch<React.SetStateAction<string | null>>
}

const ImportOptions: React.FC<Props> = (props) => {
  const { state, pendingImport, setPendingImport } = props

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const appendData = useCallback(() => {
    if (pendingImport != null) {
      state.importCards(pendingImport, false)
    }
    setPendingImport(null)
  }, [pendingImport, setPendingImport, state])

  const replaceData = useCallback(() => {
    if (pendingImport != null) {
      state.importCards(pendingImport, true)
    }
    setPendingImport(null)
  }, [pendingImport, setPendingImport, state])

  useLayoutEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [pendingImport])

  return (
    <Modal
      visible={!isEmpty(pendingImport)}
      onDismiss={() => setPendingImport(null)}
    >
      <div className={styles.container}>
        <h2 className={styles.heading}>Import List from Clipboard</h2>

        <div className={styles.options}>
          <controls.SmallButton onClick={replaceData} ref={buttonRef} secondary>
            Replace All
          </controls.SmallButton>
          <controls.SmallButton onClick={appendData} secondary>
            Add to Existing Cards
          </controls.SmallButton>
        </div>
      </div>
    </Modal>
  )
}

export default ImportOptions
