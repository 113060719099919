import * as styles from './Card.module.scss'

import React, { useCallback, useRef, useState } from 'react'
import classNames from 'classnames'

import { TextButton } from 'components/controls'

import CardImage from 'components/cards/CardImage'

import { ScratchpadState } from '../data/state'
import { Item, SimplifiedCard } from '../data/types'

import Actions from './Actions'
import Attributes from './Attributes'
import Notes from './Notes'

interface Props {
  state: ScratchpadState
  item: Item
  index: [number, number]
}

const shouldIgnoreEvent = (event: { target: any }) => {
  return ['TEXTAREA', 'INPUT', 'BUTTON', 'A'].includes(
    (event.target as HTMLElement).tagName,
  )
}

const CardDetails: React.FC<Props> = (props) => {
  const { state, item, index } = props

  const { card } = item

  const { moveCard, removeCard } = state

  const element = useRef<HTMLDivElement | null>(null)

  const [draggable, setDraggable] = useState(false)
  const [dragging, setDragging] = useState(false)

  // Use a count rather than bool since order isn't guaranteed
  const [dragCount, setDragCount] = useState(0)

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (shouldIgnoreEvent(event)) {
        return
      }
      element.current?.focus()
    },
    [],
  )

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (shouldIgnoreEvent(event)) {
        return
      }
      if (event.key === 'Backspace') {
        removeCard(index)
      } else if (event.key === 'Escape') {
        element.current?.blur()
      }
    },
    [index, removeCard],
  )

  const onDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.dataTransfer.setData('application/item', item.id)
      event.dataTransfer.dropEffect = 'move'

      setDragging(true)
    },
    [item.id],
  )

  const onDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDraggable(false)
    setDragging(false)
  }, [])

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (event.dataTransfer.types.includes('application/item')) {
      event.stopPropagation()
      setDragCount((value) => value + 1)
    }
  }, [])

  const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (event.dataTransfer.types.includes('application/item')) {
      event.stopPropagation()
      setDragCount((value) => value - 1)
    }
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      setDraggable(false)
      setDragCount(0)

      const itemID = event.dataTransfer.getData('application/item')
      if (itemID) {
        event.stopPropagation()
        moveCard(itemID, index)
      }
    },
    [index, moveCard],
  )

  return (
    <div
      className={classNames(styles.container, {
        [styles.dragging]: dragging,
        [styles.dragOver]: dragCount,
      })}
      tabIndex={0}
      ref={element}
      onClick={onClick}
      onKeyDown={onKeyDown}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <div>
        {card && (
          <CardImage canFlip flipMode="button">
            {card.name}
          </CardImage>
        )}

        {item.error && (
          <div className={styles.errorCard}>
            <div className={styles.errorCardRatio} />
          </div>
        )}
      </div>

      <div>
        <div className={styles.controls}>
          <div
            className={styles.dragHandle}
            onMouseDown={() => setDraggable(true)}
            onMouseUp={() => setDraggable(false)}
            title="Drag to reorder cards"
          >
            ⣿⣿
          </div>

          <button
            className={styles.remove}
            onClick={() => {
              state.removeCard(index)
            }}
            title={card ? `Remove card ${card.name}` : 'Remove'}
          >
            ×
          </button>
        </div>

        {item.card != null && (
          <>
            <Attributes card={item.card} />

            <Notes state={state} item={item} index={index} />

            <Actions item={item as Item & { card: SimplifiedCard }} />
          </>
        )}

        {item.error && (
          <div className={styles.error}>
            <div className={styles.errorTitle}>Error</div>
            <div className={styles.errorDetails}>{item.note}</div>

            <TextButton
              onClick={() => removeCard(index)}
              title="Remove this note"
            >
              Remove
            </TextButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardDetails
