import * as styles from './Scratchpad.module.scss'

import React, { useEffect, useMemo, useState } from 'react'
import { RecoilRoot, useRecoilState } from 'recoil'

import { useScratchpadState } from './data/state'
import { selectedGroupState } from './data/recoilState'

import Header from './Header'
import Footer from './Footer'
import EmptyView from './EmptyView'
import Group from './group/Group'
import NewGroup from './NewGroup'
import ImportOptions from './ImportOptions'
import Help from './Help'
import PasteInput from './PasteInput'

const shouldIgnoreEvent = (event: { target: any }) => {
  return ['TEXTAREA', 'INPUT'].includes((event.target as HTMLElement).tagName)
}

const Scratchpad: React.FC = () => {
  const state = useScratchpadState()

  const { importCards } = state

  const [pendingImport, setPendingImport] = useState<string | null>(null)
  const [selectedGroup, setSelectedGroup] = useRecoilState(selectedGroupState)

  const scratchpadIsEmpty = useMemo(() => {
    return (
      state.data.groups.length === 0 ||
      (state.data.groups.length === 1 &&
        state.data.groups[0].items.length === 0)
    )
  }, [state.data.groups])

  useEffect(() => {
    const onPaste = (event: Event) => {
      if (shouldIgnoreEvent(event)) {
        return
      }

      const data = (event as ClipboardEvent).clipboardData?.getData('text')
      if (data != null && data.length > 0) {
        if (scratchpadIsEmpty) {
          importCards(data)
        } else {
          setPendingImport(data)
        }
      }
    }

    window.addEventListener('paste', onPaste)

    return () => {
      window.removeEventListener('paste', onPaste)
    }
  }, [importCards, scratchpadIsEmpty])

  useEffect(() => {
    if (selectedGroup == null || selectedGroup >= state.data.groups.length) {
      setSelectedGroup(0)
    }
  }, [selectedGroup, setSelectedGroup, state.data.groups.length])

  return (
    <div className={styles.container}>
      <div>
        <Header state={state} />

        {scratchpadIsEmpty ? (
          <EmptyView />
        ) : (
          <>
            {state.data.groups.map((group, index) => (
              <Group
                key={group.id}
                groupIndex={index}
                group={group}
                state={state}
              />
            ))}

            <NewGroup state={state} />
          </>
        )}
      </div>

      <ImportOptions
        state={state}
        pendingImport={pendingImport}
        setPendingImport={setPendingImport}
      />

      <PasteInput state={state} />

      <Help />

      <Footer />
    </div>
  )
}

const ScratchPadWithState: React.FC = () => {
  return (
    <RecoilRoot>
      <Scratchpad />
    </RecoilRoot>
  )
}

export default ScratchPadWithState
