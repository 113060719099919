import * as styles from './EmptyView.module.scss'

import React from 'react'
import { useRecoilState } from 'recoil'

import { TextButton } from 'components/controls/TextButton'

import { showHelpState } from './data/recoilState'

const EmptyView: React.FC = () => {
  const [, setShowHelp] = useRecoilState(showHelpState)

  return (
    <div className={styles.container}>
      <p>Paste a list of cards with CMD + V</p>
      <p>
        <TextButton onClick={() => setShowHelp(true)}>More Info</TextButton>
      </p>
    </div>
  )
}

export default EmptyView
